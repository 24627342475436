import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { AuthContextProvider } from './contexts/authProvider';
import { SettingContextProvider } from './contexts/settingProvider';
import { FormOptionsProvider } from './contexts/formProvider';
import { TaskProvider } from './contexts/taskProvider';
import 'antd/dist/reset.css';
import './css/main.css';
import './css/dark.css';
import './css/rwd.css';
import './utils/axiosConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <SettingContextProvider>
        <FormOptionsProvider>
          <TaskProvider>
            <App />
          </TaskProvider>
        </FormOptionsProvider>
      </SettingContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>
  //</React.StrictMode>
);
